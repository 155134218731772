<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>Add New Owner</ion-title>
        <Logout slot="end" />
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Add New Owner</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-grid :fixed="true" class="flex">
        <ion-row class="ion-align-items-center w-full">
          <ion-col size="12" class="ion-text-center">
            <ion-item>
              <ion-icon class="color-blue pd-r-10" :icon="personOutline" />
              <ion-input type="email" autocomplete="email" inputmode="email" v-model="email"
                         placeholder="email@example.com" ></ion-input>
            </ion-item>
            <ion-item>
              <ion-icon class="color-blue pd-r-10" :icon="lockClosed" />
              <ion-input type="password" autocomplete="current-password" v-model="password"
                         placeholder="Password" ></ion-input>
            </ion-item>
            <div class="mt-15px">
              <ion-button :disabled="!(email && password)" color="primary" fill="solid" expand="full" @click="doCreate">
                Create a new owner account
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
import { IonIcon, IonButton, IonInput, IonItem } from '@ionic/vue';
import { IonGrid, IonRow, IonCol } from '@ionic/vue';
import { defineComponent } from 'vue';
import { onIonViewWillEnter } from '@ionic/vue';
import { chevronBackOutline, personOutline, lockClosed } from "ionicons/icons";
import Logout from '@/components/Logout.vue';
import openToast from "@/lib/toast";
import libStore from "@/lib/libStore";
import loader from "@/lib/loader";

export default defineComponent({
  name: 'Tab1',
  components: {
    Logout,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonItem,
    IonButton,
    IonInput
  },
  setup () {
    const { loading } = loader()
    const { store, goToLogin, goToHome, authErrorMessage } = libStore();
    onIonViewWillEnter(() => {
      goToLogin();
    });
    return {
      store,
      goToHome,
      authErrorMessage,
      chevronBackOutline,
      personOutline,
      lockClosed,
      loading
    }
  },
  data() {
    return {
      email: null,
      password: null
    }
  },
  methods: {
    async doCreate() {
      try {
        const isSuccess = await this.store.dispatch('user/createAccount', {email: this.email, password: this.password});
        if (isSuccess === false) {
          await openToast(this.authErrorMessage());
        } else {
          await openToast("New Owner Account Created Successfully");
          this.email = null;
          this.password = null;
        }
      } catch (e) {
        await openToast(this.authErrorMessage(e));
      }
    }
  }
});
</script>

<style scoped>
.pd-r-10 {
  padding-right: 10px;
}
.mt-15px {
  margin-top: 15px;
}
.flex {
  display: flex;
}
.w-full {
  width: 100%;
}
</style>