
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
import { IonIcon, IonButton, IonInput, IonItem } from '@ionic/vue';
import { IonGrid, IonRow, IonCol } from '@ionic/vue';
import { defineComponent } from 'vue';
import { onIonViewWillEnter } from '@ionic/vue';
import { chevronBackOutline, personOutline, lockClosed } from "ionicons/icons";
import Logout from '@/components/Logout.vue';
import openToast from "@/lib/toast";
import libStore from "@/lib/libStore";
import loader from "@/lib/loader";

export default defineComponent({
  name: 'Tab1',
  components: {
    Logout,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonItem,
    IonButton,
    IonInput
  },
  setup () {
    const { loading } = loader()
    const { store, goToLogin, goToHome, authErrorMessage } = libStore();
    onIonViewWillEnter(() => {
      goToLogin();
    });
    return {
      store,
      goToHome,
      authErrorMessage,
      chevronBackOutline,
      personOutline,
      lockClosed,
      loading
    }
  },
  data() {
    return {
      email: null,
      password: null
    }
  },
  methods: {
    async doCreate() {
      try {
        const isSuccess = await this.store.dispatch('user/createAccount', {email: this.email, password: this.password});
        if (isSuccess === false) {
          await openToast(this.authErrorMessage());
        } else {
          await openToast("New Owner Account Created Successfully");
          this.email = null;
          this.password = null;
        }
      } catch (e) {
        await openToast(this.authErrorMessage(e));
      }
    }
  }
});
